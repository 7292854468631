import styled from '@emotion/styled';
import 'react-multi-carousel/lib/styles.css';

const CoverPhotoStyle = styled.div`
  aspect-ratio: 6.2 / 1;
  overflow: hidden;
  margin: 16px 0px;

  .carousel-container {
    margin: 0px;
    overflow: hidden;
  }

  .carousel-img-li {
    padding: 0px 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .react-multiple-carousel__arrow {
    min-width: 60px;
    min-height: 60px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    ::before {
      color: #00532a;
      font-weight: bold;
      font-size: 24px;
    }
  }

  .carousel-arrow-right {
    right: calc(4% + 1px);
  }

  .carousel-arrow-left {
    left: calc(4% + 1px);
  }

  ${(props: any) => props.theme.breakpoints.down('xs')} {
    .carousel-img-li {
      padding: 0px 4px;
    }
  }
  ${(props: any) => props.theme.breakpoints.down('lg')} {
    aspect-ratio: 3.1 / 1;
    ${(props: any) => props.haveCoupon && 'margin-top: 6rem;'}
  }
`;

export default CoverPhotoStyle;

import { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-intersection-observer';
import Carousel from 'react-multi-carousel';

import InteractiveCoverImage from './interactiveCoverImage';
import CoverPhotoStyle from './style';

import ArrowCircleButton from 'src/components/atoms/arrowCircleButton';
import { ICoverPhoto } from 'src/interfaces/app';

type Props = {
  coverPhotos: ICoverPhoto[];
  loading: boolean;
};

const CoverPhoto: FC<Props> = ({ coverPhotos = [], loading }) => {
  const theme = useTheme();

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 6000, min: theme.breakpoints.values.xl },
      items: 1,
    },
    desktop: {
      breakpoint: { max: theme.breakpoints.values.xl, min: theme.breakpoints.values.lg },
      items: 1,
    },
    tablet: {
      breakpoint: { max: theme.breakpoints.values.lg, min: theme.breakpoints.values.sm },
      items: 1,
    },
    mobile: {
      breakpoint: { max: theme.breakpoints.values.sm, min: 0 },
      items: 1,
    },
  };

  const matches = useMediaQuery(`(min-width:${theme.breakpoints.values.lg}px)`);
  // eslint-disable-next-line no-undef
  let buttonPressTimer: NodeJS.Timeout;
  let isClick = true;
  const coverPhotoTouchStart = () => {
    buttonPressTimer = setTimeout(() => {
      isClick = false;
    }, 300);
  };

  const coverPhotoTouchEnd = (link: string, target: string) => {
    clearTimeout(buttonPressTimer);
    if (isClick) {
      window.open(link, target);
    } else {
      isClick = true;
    }
  };

  return (
    <CoverPhotoStyle ref={ref}>
      {inView && (
        <Carousel
          responsive={responsive}
          centerMode={matches}
          infinite
          removeArrowOnDeviceType={['mobile', 'tablet']}
          autoPlay={!loading}
          autoPlaySpeed={7000}
          pauseOnHover
          draggable
          containerClass="carousel-container"
          itemClass="carousel-img-li"
          ssr
          customRightArrow={<ArrowCircleButton direction="right" className="carousel-arrow-right" />}
          customLeftArrow={<ArrowCircleButton direction="left" className="carousel-arrow-left" />}
        >
          {coverPhotos.map((item: ICoverPhoto) => (
            <InteractiveCoverImage
              key={item.id}
              item={item}
              coverPhotoTouchStart={coverPhotoTouchStart}
              coverPhotoTouchEnd={coverPhotoTouchEnd}
            />
          ))}
        </Carousel>
      )}
    </CoverPhotoStyle>
  );
};

export default CoverPhoto;

import Image from 'next/image';
import { FC } from 'react';

import { Skeleton } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import InteractiveCoverImageStyle from './style';

import { ICoverPhoto } from '@interfaces/app';

type Props = {
  item: ICoverPhoto;
  coverPhotoTouchStart: () => void;
  coverPhotoTouchEnd: (link: string, target: string) => void;
};

const InteractiveCoverImage: FC<Props> = ({ item, coverPhotoTouchStart, coverPhotoTouchEnd }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <InteractiveCoverImageStyle
      ref={ref}
      onTouchStart={coverPhotoTouchStart}
      onTouchEnd={() => coverPhotoTouchEnd(item.link, item.target)}
      onMouseDown={coverPhotoTouchStart}
      onMouseUp={() => coverPhotoTouchEnd(item.link, item.target)}
    >
      {inView ? (
        <Image
          alt={item.alt || 'คอร์สใหม่ล่าสุด'}
          src={item.img}
          layout="responsive"
          width={960}
          height={304}
          priority
        />
      ) : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ aspectRatio: '3.1 / 1', height: '100%', borderRadius: '10px' }}
        />
      )}
    </InteractiveCoverImageStyle>
  );
};

export default InteractiveCoverImage;
